import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from './components/header'
import Photo from './components/photo'
import About from './components/about'
import Service from './components/service'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
       <Router>
      <Header/>
      <Routes>
        <Route exact path="/" element={<App></App>} />
        <Route path="/photo" element={<Photo/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/service" element={<Service/>} />
      </Routes>
    </Router>
  </React.StrictMode>,
  <><hr></hr><br></br></>
);


