import '../App.css';
import React from 'react';
import Logo from '../images/JCSDC_002.jpg';
import Logo1 from '../images/JCSDC_003.jpg';
import Logo2 from '../images/JCSDC_004.jpg';
import Logo3 from '../images/JCSDC_005.jpg';
import Log4 from '../images/JCSDC_006.jpg';
import Logo5 from '../images/JCSDC_007.jpg';
import Log6 from '../images/JCSDC_008.jpg';
import Logo7 from '../images/JCSDC_009.jpg';
import Logo8 from '../images/JCSDC_010.jpg';
import Logo9 from '../images/JCSDC_011.jpg';
import Log10 from '../images/JCSDC_012.jpg';
import Logo11 from '../images/JCSDC_013.jpg';
import Log12 from '../images/JCSDC_014.jpg';
import Logo13 from '../images/JCSDC_015.jpg';
import Card from 'react-bootstrap/Card';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function photo() {
  return (
    <div class="App">
    <div class="background-image">
    <Container>
      <Row>                
        <Col>
        <Card style={{ width: '25rem', height: '18 rem'}}>      
          <Card.Body>
            <Card.Header>Joy</Card.Header>        
            <Card.Text>
              <img src={Logo} style={{borderRadius:"25%", height:"350px", width:"350px"}}></img>
            </Card.Text>
            
          </Card.Body>
        </Card>
        </Col>
        <Col>
        <Card style={{ width: '25rem', height: '18 rem'}}>      
          <Card.Body>
            <Card.Header>Delight</Card.Header>        
            <Card.Text>
              <img src={Logo1} style={{borderRadius:"25%", height:"350px", width:"350px"}}></img>
            </Card.Text>
            
          </Card.Body>
        </Card>  
        </Col>
        <Col>
        <Card style={{ width: '25rem', height: '18 rem'}}>      
          <Card.Body>
            <Card.Header>Bliss</Card.Header>        
            <Card.Text>
              <img src={Logo2} style={{borderRadius:"25%", height:"350px", width:"350px"}}></img>
            </Card.Text>
            
          </Card.Body>
        </Card>  
        </Col>
        <Col>
        <Card style={{ width: '25rem', height: '18 rem'}}>      
          <Card.Body>
            <Card.Header>Contentment</Card.Header>        
            <Card.Text>
              <img src={Logo3} style={{borderRadius:"25%", height:"350px", width:"350px"}}></img>
            </Card.Text>
            
          </Card.Body>
        </Card>  
        </Col>
      </Row>
     
      
    </Container>
      
    </div>

       
    </div>
  );
}

export default photo;