import './App.css';
import React from 'react';
import Logo from '../src/images/Logo.jpg';
import Ma from '../src/images/Ma.jpg'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import myLogo from '../src/images/JCSDC_004.jpg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function App() {
  return (
    <div className="App">
    <div className='background-image'>
    <Container>
      <Row>                
        <Col>
        <Card style={{ width: '18rem', height: '18 rem'}}>      
          <Card.Body>
            <Card.Header>JCSDC</Card.Header>        
            <Card.Text>
              <img src={Logo} style={{borderRadius:"50%", height:"275px", width:"275px"}}></img>
            </Card.Text>
            
          </Card.Body>
        </Card>
        </Col>
        <Col>
        <Card style={{ width: '18rem', height: '18 rem' }}>      
          <Card.Body>  
            <Card.Header>Mythological Origins</Card.Header>      
            <Card.Text>
              
              <p>
                  The origins of Durga Puja can be traced back to Hindu mythology, particularly the Devi Mahatmya (also known as Durga Saptashati), a sacred text that narrates the battle of goddess Durga against the demon king Mahishasura. The goddess is believed to have been created by combining the energies of various Hindu gods to defeat Mahishasura and his demon army.
              </p>
              </Card.Text>
            
          </Card.Body>
        </Card>        
        </Col>
        <Col>
        <Card style={{width: '18rem', height: '18 rem' }}>      
          <Card.Body>  
            <Card.Header>Ancient Roots</Card.Header>      
            <Card.Text>
              
              <p>
                The worship of goddess Durga can be traced back to ancient times, with evidence suggesting that the ritualistic worship of a feminine divine power has been prevalent in the Indian subcontinent for millennia. The form and rituals of Durga Puja, however, have evolved over the centuries.
              </p>
              </Card.Text>
            
          </Card.Body>
        </Card>
        </Col>
        <Col>
        <Card style={{ width: '18rem', height: '18 rem' }}>      
          <Card.Body>  
            <Card.Header>Evolution</Card.Header>      
            <Card.Text>
              
              <p>
                During the medieval period, Durga Puja gained popularity and patronage from various rulers and nobility across the Indian subcontinent. It became a more elaborate and organized event, with communities coming together to celebrate the goddess and her victory over evil.
              </p>
              </Card.Text>
            
          </Card.Body>
        </Card>        
        </Col>
        <Col>
        <Card style={{ width: '18rem', height: '18 rem' }}>      
          <Card.Body>  
            <Card.Header>Kolkata</Card.Header>      
            <Card.Text>
              
              <p>
              Kolkata, the capital city of the Indian state of West Bengal, is renowned for its grand and elaborate celebrations of Durga Puja. The festival is deeply ingrained in the cultural fabric of the city and is a significant event that brings the community together.
              </p>
              </Card.Text>
            
          </Card.Body>
        </Card>
        </Col> 
        <Col>
        <Card style={{ width: '18rem', height: '18 rem' }}>      
          <Card.Body>  
            <Card.Header>Nayabad</Card.Header>      
            <Card.Text>
            <p>
            Durga Puja organised by Jadavpur Cooperative Surbojanin Durgotsav Committee under Jadavpur Cooperative Land &Housing Society Ltd.at 1141/2,Nayabad,is a time for unity,cultural expression and spiritual reflection,bringing the community together to celebrate their faith and heritage.It is dedicated to worship the Goddess Durga symbolizing strength,power and victory of good over evil.
            </p>  
            
            </Card.Text>
            
          </Card.Body>
        </Card>
        </Col>   
      </Row>
     
      
    </Container>
      
    </div>

       
    </div>
  );
}

export default App;
