import React from 'react'
import {Navbar, Nav} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import Logo from '../Logo'

const header = () => {
    return (
        <Navbar bg="light" expand="lg" sticky="top">
            <LinkContainer to="/">
  <Navbar.Brand>Home</Navbar.Brand>
  </LinkContainer>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
        <LinkContainer to="/photo">
      <Nav.Link>Gallery 1</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/about">
      <Nav.Link>Gallery 2</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/service">
      <Nav.Link>Gallery 3</Nav.Link>
      </LinkContainer>
    </Nav>
  </Navbar.Collapse>
</Navbar>

    )
}

export default header
